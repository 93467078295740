import React, { Fragment } from 'react'

import { Link } from 'gatsby'

import styles from './style.module.scss'

export default ({ data }) => {
    return (
        <Fragment>
            <h2>Related posts</h2>
            <ul className={styles.list}>
                {data.relatedPosts.edges.map(({ node: post }) => {
                    return (
                        <li key={post.title}>
                            <Link
                                to={'/blog/' + post.slug}
                                dangerouslySetInnerHTML={{ __html: post.title }}
                            />
                        </li>
                    )
                })}
            </ul>
        </Fragment>
    )
}
